<template>
  <div class="compare">
    <div v-if="isFiltered">
      <Filters isType="compare" @on-compare-button="handleCompareButton" />
      <InfoBox
        v-if="isInfobox"
        message="Select Compare CUC code and Revision to Compare"
      />
      <ComparePopup v-else />
    </div>
    <div v-else class="container">
      <InfoBox message="Select CUC code and Revision to view Comparison" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {
    Filters: () =>
      import(/* webpackPrefetch: true */ "../formulation-mapping/Filters"),
    InfoBox: () => import(/* webpackPrefetch: true */ "../common/InfoBox"),
    ComparePopup: () =>
      import(/* webpackPrefetch: true */ "../formulation-mapping/ComparePopup"),
  },
  data: () => ({
    isInfobox: true,
  }),
  computed: {
    ...mapGetters({ isFiltered: "GET_IS_FILTERED" }),
  },
  methods: {
    handleCompareButton() {
      this.isInfobox = true;
      this.$nextTick(() => {
        this.isInfobox = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  text-align: center;
}
</style>
