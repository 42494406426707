var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isFiltered
      ? _c("div", { staticClass: "flex flex-col relative" }, [
          _c("div", { staticClass: "button-container" }, [
            _c("span", { staticClass: "label" }, [
              _vm._v("CUC Code: " + _vm._s(_vm.cucCode))
            ]),
            _c(
              "div",
              { staticClass: "flex flex-row" },
              [
                _c(
                  "Button",
                  {
                    attrs: { disabled: !_vm.role.isUser },
                    on: {
                      click: function($event) {
                        return _vm.openModalProduct("add")
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 20 20"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                          }
                        })
                      ]
                    ),
                    _vm._v(" Add Product Mapping ")
                  ]
                ),
                _c(
                  "Button",
                  {
                    staticClass: "ml-4",
                    attrs: { disabled: !_vm.role.isUser },
                    on: {
                      click: function($event) {
                        return _vm.openModalCreateProduct("create")
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 20 20"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                          }
                        })
                      ]
                    ),
                    _vm._v(" Create New Product ")
                  ]
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticClass: "container",
              staticStyle: { "margin-top": "10px", "padding-left": "0" }
            },
            [
              _vm.isLoader
                ? _c("Loader", { attrs: { loading: _vm.isLoader } })
                : _c("div", [
                    _c("div", { staticClass: "table-wrapper" }, [
                      _c("table", { staticClass: "pioti-table table-auto" }, [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [_vm._v("Country")]),
                            _c("th", [_vm._v("Language")]),
                            _c("th", [_vm._v("Brand")]),
                            _c("th", [_vm._v("Product")]),
                            _c("th", [_vm._v("Variation")]),
                            _c("th", [_vm._v("Marketed")]),
                            _c("th", [_vm._v("Variation Start Date")]),
                            _c("th", [_vm._v("Variation End Date")]),
                            _c("th", [_vm._v("CUC Start Date")]),
                            _c("th", [_vm._v("CUC End Date")]),
                            _c("th", [_vm._v("CUC Status")]),
                            _c("th", [_vm._v("Historic")])
                          ])
                        ]),
                        _vm.tableDataRows.length > 0
                          ? _c(
                              "tbody",
                              _vm._l(_vm.tableDataRows, function(row, index) {
                                return _c("tr", { key: index }, [
                                  _c(
                                    "td",
                                    { staticClass: "tooltip table-tooltip" },
                                    [
                                      _c("Ellipsis", {
                                        attrs: {
                                          data: row.Country,
                                          subString: _vm.subString
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "tooltip table-tooltip" },
                                    [
                                      _c("Ellipsis", {
                                        attrs: {
                                          data: row.Language,
                                          subString: _vm.subString
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "tooltip table-tooltip" },
                                    [
                                      _c("Ellipsis", {
                                        attrs: {
                                          data: row.Brand,
                                          subString: _vm.subString
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "tooltip table-tooltip" },
                                    [
                                      _c("Ellipsis", {
                                        attrs: {
                                          data: row.Product,
                                          subString: _vm.subString
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "tooltip table-tooltip" },
                                    [
                                      _c("Ellipsis", {
                                        attrs: {
                                          data: row.ProductVariationName,
                                          subString: _vm.subString
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "tooltip table-tooltip" },
                                    [
                                      _c("Checkbox", {
                                        attrs: { checked: row.Marketed }
                                      }),
                                      row.Marketed
                                        ? _c("Tooltip", {
                                            attrs: {
                                              position: "left",
                                              hoverText:
                                                "Product will flow to WIOP"
                                            }
                                          })
                                        : _c("Tooltip", {
                                            attrs: {
                                              position: "left",
                                              hoverText:
                                                "Product will not flow to WIOP"
                                            }
                                          })
                                    ],
                                    1
                                  ),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate")(
                                          row.ProductVariationStartDate
                                        )
                                      )
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate")(
                                          row.ProductVariationEndDate
                                        )
                                      )
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate")(row.CucStartDate)
                                      )
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate")(row.CucEndDate)
                                      )
                                    )
                                  ]),
                                  _c("td", [_vm._v(_vm._s(row.CucStatusText))]),
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "actions tooltip table-tooltip"
                                    },
                                    [
                                      row.CucStatusText === "Delisted"
                                        ? _c("Checkbox", {
                                            attrs: { checked: row.Historic }
                                          })
                                        : _c("span", { staticClass: "empty" }),
                                      row.Historic &&
                                      row.CucStatusText === "Delisted"
                                        ? _c("Tooltip", {
                                            attrs: {
                                              position: "left",
                                              hoverText:
                                                "Formulation will flow to WIOP"
                                            }
                                          })
                                        : !row.Historic &&
                                          row.CucStatusText === "Delisted"
                                        ? _c("Tooltip", {
                                            attrs: {
                                              position: "left",
                                              hoverText:
                                                "Formulation will not flow to WIOP"
                                            }
                                          })
                                        : _vm._e(),
                                      _vm.role.isUser
                                        ? _c(
                                            "IconButton",
                                            {
                                              attrs: { buttonType: "edit" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openModalProduct(
                                                    "edit",
                                                    row
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Edit")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ])
                              }),
                              0
                            )
                          : _c("tbody", [
                              _c("tr", [
                                _c(
                                  "td",
                                  { attrs: { colspan: "12" } },
                                  [_c("NoDetailsBox")],
                                  1
                                )
                              ])
                            ])
                      ])
                    ])
                  ])
            ],
            1
          )
        ])
      : _c(
          "div",
          { staticClass: "container" },
          [
            _c("InfoBox", {
              attrs: {
                message:
                  "Select CUC code and Revision to view Formulation Brand - Product Mapping"
              }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }