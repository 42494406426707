<template>
  <div>
    <div v-if="isFiltered" class="flex flex-col relative">
      <div class="button-container">
        <span class="label">CUC Code: {{ cucCode }}</span>
        <div class="flex flex-row">
          <!-- content .... -->
          <Button @click="openModalProduct('add')" :disabled="!role.isUser">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path
                d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
              />
            </svg>
            Add Product Mapping
          </Button>
          <Button
            @click="openModalCreateProduct('create')"
            class="ml-4"
            :disabled="!role.isUser"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path
                d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
              />
            </svg>
            Create New Product
          </Button>
        </div>
      </div>

      <div class="container" style="margin-top: 10px; padding-left: 0">
        <Loader :loading="isLoader" v-if="isLoader" />
        <div v-else>
          <div class="table-wrapper">
            <table class="pioti-table table-auto">
              <thead>
                <tr>
                  <th>Country</th>
                  <th>Language</th>
                  <th>Brand</th>
                  <th>Product</th>
                  <th>Variation</th>
                  <th>Marketed</th>
                  <th>Variation Start Date</th>
                  <th>Variation End Date</th>
                  <th>CUC Start Date</th>
                  <th>CUC End Date</th>
                  <th>CUC Status</th>
                  <th>Historic</th>
                </tr>
              </thead>
              <tbody v-if="tableDataRows.length > 0">
                <tr v-for="(row, index) in tableDataRows" :key="index">
                  <td class="tooltip table-tooltip">
                    <Ellipsis :data="row.Country" :subString="subString" />
                  </td>
                  <td class="tooltip table-tooltip">
                    <Ellipsis :data="row.Language" :subString="subString" />
                  </td>
                  <td class="tooltip table-tooltip">
                    <Ellipsis :data="row.Brand" :subString="subString" />
                  </td>
                  <td class="tooltip table-tooltip">
                    <Ellipsis :data="row.Product" :subString="subString" />
                  </td>
                  <td class="tooltip table-tooltip">
                    <Ellipsis
                      :data="row.ProductVariationName"
                      :subString="subString"
                    />
                  </td>
                  <td class="tooltip table-tooltip">
                    <Checkbox :checked="row.Marketed" />
                    <Tooltip
                      v-if="row.Marketed"
                      :position="'left'"
                      :hoverText="'Product will flow to WIOP'"
                    />
                    <Tooltip
                      v-else
                      :position="'left'"
                      :hoverText="'Product will not flow to WIOP'"
                    />
                  </td>
                  <td>{{ row.ProductVariationStartDate | formatDate }}</td>
                  <td>{{ row.ProductVariationEndDate | formatDate }}</td>
                  <td>{{ row.CucStartDate | formatDate }}</td>
                  <td>{{ row.CucEndDate | formatDate }}</td>
                  <td>{{ row.CucStatusText }}</td>
                  <td class="actions tooltip table-tooltip">
                    <Checkbox
                      :checked="row.Historic"
                      v-if="row.CucStatusText === 'Delisted'"
                    />
                    <span v-else class="empty"></span>
                    <Tooltip
                      v-if="row.Historic && row.CucStatusText === 'Delisted'"
                      :position="'left'"
                      :hoverText="'Formulation will flow to WIOP'"
                    />
                    <Tooltip
                      v-else-if="
                        !row.Historic && row.CucStatusText === 'Delisted'
                      "
                      :position="'left'"
                      :hoverText="'Formulation will not flow to WIOP'"
                    />
                    <IconButton
                      :buttonType="'edit'"
                      @click="openModalProduct('edit', row)"
                      v-if="role.isUser"
                      >Edit</IconButton
                    >
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="12">
                    <!--NO DATA-->
                    <NoDetailsBox />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container">
      <InfoBox
        message="Select CUC code and Revision to view Formulation Brand - Product Mapping"
      />
    </div>
  </div>
</template>

<script>
import { Bus } from "./../../eventBus";
import { mapGetters, mapMutations, mapActions } from "vuex";
import "../../filters";
import authentication from "../../authentication";
import loggerService from "../../services/logger";
const FormulationBrandProductMapping = () =>
  import(
    /* webpackPrefetch: true */ "../../components/formulation-mapping/FormulationProductMappingPopup"
  );
const Product = () => import(/* webpackPrefetch: true */ "../common/Product");
export default {
  components: {
    Loader: () => import(/* webpackPrefetch: true */ "./../common/Loader"),
    InfoBox: () => import(/* webpackPrefetch: true */ "../common/InfoBox"),
    Button: () => import(/* webpackPrefetch: true */ "../common/Button"),
    NoDetailsBox: () =>
      import(/* webpackPrefetch: true */ "../common/NoDetailsBox"),
    Ellipsis: () => import(/* webpackPrefetch: true */ "../common/Ellipsis"),
    Checkbox: () => import(/* webpackPrefetch: true */ "../common/Checkbox"),
    IconButton: () =>
      import(/* webpackPrefetch: true */ "../common/IconButton"),
    Tooltip: () => import(/* webpackPrefetch: true */ "../common/Tooltip"),
  },
  data: () => ({
    isType: "",
    isLoader: true,
    subString: 8,
    tableHeaders: [
      {
        Country: "",
        Language: "",
        Brand: "",
        ProductName: "",
        ProductStartDate: "",
        ProductEndDate: "",
        FormulationStartDate: "",
        FormulationEndDate: "",
        CUCStatus: "",
      },
    ],
    dropdownMenuData: [
      { title: "Edit", type: "edit" },
      { title: "Delete", type: "delete" },
    ],
    isMappingType: "",
  }),
  created() {
    Bus.$on("on-filtered", (val) => {
      this.isRenew = val;
      this.isArchive = val;
    });
    this.getCountries();
    if (this.revisionNumber != null) {
      this.getProductMappings();
    }
  },
  computed: {
    userEmail() {
      if (!authentication.isAuthenticated) {
        return null;
      }
      return authentication.getUserEmail();
    },
    ...mapGetters({
      isFiltered: "GET_IS_FILTERED",
      cucCode: "GET_CUC_CODE_PRIMARY",
      revisionNumber: "GET_REVISION_PRIMARY",
      tableDataRows: "GET_FORMULATIONS",
      role: "GET_USER_ROLE",
      cucStatus: "GET_CUC_STATUS",
    }),
  },
  methods: {
    ...mapActions({
      fetchCountries: "fetchCountries",
      getFormulations: "fetchFormulations",
    }),
    ...mapMutations({
      updateFormulationBrandProductMappingPage: "SET_FORMULATION_IN_PRODUCT",
      updateFormulations: "SET_FORMULATIONS",
      updateFormulation: "SET_FORMULATION",
      updateIsProductType: "SET_IS_PRODUCT_TYPE",
    }),
    openModalProduct(type, data) {
      this.updateIsProductType(type);
      this.updateFormulation(data);
      this.isMappingType = type;
      if (this.isRenew || this.isArchive) {
        loggerService.logError600("Formulation selected is in Archived status. Please renew to perform the mapping");
      } else {
        const title =
          type === "add"
            ? "Formulation Product Brand Mapping"
            : "Edit Formulation Mapping";
        this.updateFormulationBrandProductMappingPage("formulation");
        Bus.$emit("open", {
          component: FormulationBrandProductMapping,
          title: title,
          size: "md",
          closeOnClick: false,
        });
      }
    },
    openModalCreateProduct(type) {
      this.updateIsProductType(type);
      Bus.$emit("open", {
        component: Product,
        title: "Create New Product",
        size: "xl",
        closeOnClick: false,
      });
    },
    getCountries(){
      this.fetchCountries();
    },
    async getProductMappings() {
      try {
        const payLoad = {
          cucCode: this.cucCode,
          revisionNumber: this.revisionNumber.Value,
          cucStatus:
            this.cucStatus === null || this.cucStatus === ""
              ? ""
              : this.cucStatus,
        };
        this.getFormulations(payLoad);

        this.isLoader = false;
      } catch (error) {
        loggerService.logError(error);
        this.updateFormulations([]);
        this.isLoader = false;
      }
    },
  },
  watch: {
    revisionNumber() {
      this.getProductMappings();
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  text-align: center;
  table {
    border-radius: 3px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
    tr {
      th,
      td {
        min-width: auto;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          min-width: 70px;
          max-width: 70px;
        }
        &:nth-child(7) {
          min-width: 120px;
          max-width: 120px;
        }
        &:nth-child(8) {
          min-width: 115px;
          max-width: 115px;
        }
        &:nth-child(12) {
          min-width: 90px;
          max-width: 90px;
        }
      }
      td.actions {
        display: inline-flex !important;
        .empty {
          width: 25px;
        }
      }
    }
  }
}
.button-container {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.19) 0 0 8px 0;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 5px;
  border-radius: 3px;
  z-index: auto;
  .label {
    font-size: 1.1rem;
    font-weight: 600;
  }
}
.table-wrapper {
  max-height: 390px;
  max-width: 100%;
}
</style>
