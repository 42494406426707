var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "compare" }, [
    _vm.isFiltered
      ? _c(
          "div",
          [
            _c("Filters", {
              attrs: { isType: "compare" },
              on: { "on-compare-button": _vm.handleCompareButton }
            }),
            _vm.isInfobox
              ? _c("InfoBox", {
                  attrs: {
                    message: "Select Compare CUC code and Revision to Compare"
                  }
                })
              : _c("ComparePopup")
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "container" },
          [
            _c("InfoBox", {
              attrs: {
                message: "Select CUC code and Revision to view Comparison"
              }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }