<template>
  <div>
    <div v-if="isFiltered">
      <Tabs :tab-items="tabItems" isTable />
    </div>

    <div v-else class="container">
      <InfoBox
        message="Select CUC code and Revision to view Additional Information"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "additional-information",
  components: {
    Tabs: () => import(/* webpackPrefetch: true */ "../common/Tabs"),
    InfoBox: () => import(/* webpackPrefetch: true */ "../common/InfoBox"),
  },
  data: () => ({
    tabItems: [
      {
        name: "Nominal Composition ",
        isVerticalTable: false,
        tableHeaders: [
          {
            Inclusion: "10.306000",
            Ingredient: "Aqua",
            IngredientFunction: "",
            IngredientNumber: "PURE700357",
          },
        ],
        tableDataRows: null,
      },
      {
        name: "Formulation Details",
        isVerticalTable: true,
        tableDataRows: null,
      },
    ],
  }),
  computed: {
    ...mapGetters({
      isFiltered: "GET_IS_FILTERED",
      additionalInfo: "GET_ADDITIONAL_INFORMATION",
    }),
  },
  methods: {
    getAdditionalInfo() {
      if (this.additionalInfo !== null) {
        this.tabItems[0].tableDataRows = this.additionalInfo[
          "NominalComposition"
        ] === null ? [] : this.additionalInfo[
          "NominalComposition"
        ];
        this.tabItems[1].tableDataRows = this.additionalInfo[
          "ExtraInterspecDetails"
        ] ===null ? [] : this.additionalInfo[
          "ExtraInterspecDetails"
        ];
      }
    },
  },
  created() {
    this.getAdditionalInfo();
  },
  watch: {
    additionalInfo() {
      this.getAdditionalInfo();
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  text-align: center;
}
</style>
